/// <reference path="../groupthink-js.d.ts" />

import { fetcher, apiRequest } from '../lib';
import useSWR from 'swr';
import { useUser } from './user';
import _ from 'underscore';

enum RoomUserRole {
  Owner = 'owner',
  Participant = 'participant',
}

export const useGuestRoom = (id?: string) => {
  const {
    data: room,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'room.guestShow'>>(
    () => (id ? `/v1/rooms/${id}?token=true` : false),
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const { user } = useUser('me');

  let isCurrentUserRoomOwner = false;
  let isCurrentUserRoomParticipant = false;

  if (user && room?.data && room?.data?.users) {
    // is_editor is only available on UserResource
    // The agenda.show endpoint can return an AgendaResource or a BriefAgendaResource, but only AgendaResource will have a UserResource.
    const currentUser = _.indexBy(room.data.users, 'id')[user.id] as Groupthink.UserResource;

    isCurrentUserRoomOwner = currentUser?.role === RoomUserRole.Owner;
    isCurrentUserRoomParticipant = currentUser?.role === RoomUserRole.Participant;
  }

  return {
    room: room?.data
      ? // @ts-ignore https://github.com/Groupthinkai/frontend/issues/2406
        { ...room.data, has_unread: room.unread?.includes(room.data.id) ?? false }
      : undefined,
    isCurrentUserRoomOwner,
    isCurrentUserRoomParticipant,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useRoom = (workspaceId: string | null, id: string | null) => {
  const url = workspaceId && id ? `/v1/workspaces/${workspaceId}/rooms/${id}` : '';

  const {
    data: room,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'room.show'>>(
    () => (id ? `${url}?token=true` : false),
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  const updateRoom = async <RouteName = 'room.update'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) => {
    apiRequest<RouteName>(url, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });
  };

  const deleteRoom = async <RouteName = 'room.delete'>({
    setErrors,
    setIsDeleting,
    onSuccess,
  }: Groupthink.DeleteOperationOptions<RouteName>) =>
    apiRequest<RouteName>(url, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  const inviteUser = async ({ setErrors, setIsInviting, onSuccess, payload }) =>
    apiRequest(`${url}/users`, mutate, 'POST', {
      setErrors,
      setLoading: setIsInviting,
      payload,
      onSuccess,
    });

  const updateUser = async (userId: string, { setErrors, setIsUpdating, onSuccess, payload }) =>
    apiRequest(`${url}/users/${userId}`, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const removeUser = async (userId: string, { setErrors, setIsRemoving, onSuccess }) =>
    apiRequest(`${url}/users/${userId}`, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsRemoving,
      onSuccess,
    });

  const { user } = useUser('me');

  let isCurrentUserRoomOwner = false;
  let isCurrentUserRoomParticipant = false;

  if (user && room?.data && room?.data?.users) {
    // is_editor is only available on UserResource
    // The agenda.show endpoint can return an AgendaResource or a BriefAgendaResource, but only AgendaResource will have a UserResource.
    const currentUser = _.indexBy(room.data.users, 'id')[user.id] as Groupthink.UserResource;
    isCurrentUserRoomOwner = currentUser?.role === 'owner';
    isCurrentUserRoomParticipant = currentUser?.role === 'participant';
  }

  return {
    room: room?.data
      ? // @ts-ignore https://github.com/Groupthinkai/frontend/issues/2406
        { ...room.data, has_unread: room.unread?.includes(room.data.id) ?? false }
      : undefined,
    isCurrentUserRoomOwner,
    isCurrentUserRoomParticipant,
    isLoading,
    isError: error,
    mutate,
    updateRoom,
    deleteRoom,
    inviteUser,
    updateUser,
    removeUser,
  };
};

export const useRooms = (workspaceId: string) => {
  const url = workspaceId ? `/v1/workspaces/${workspaceId}/rooms` : '';

  const {
    data: room,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'room.index'>>(url, fetcher, {
    keepPreviousData: true,
  });

  const createRoom = async <RouteName = 'room.create'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) => {
    apiRequest<RouteName>(url, mutate, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });
  };

  const rooms = room?.data.map((roomRow) => ({
    ...roomRow,
    // @ts-ignore https://github.com/Groupthinkai/frontend/issues/2406
    has_unread: room.unread?.includes(roomRow.id) ?? false,
  }));

  return {
    rooms,
    isLoading,
    isError: error,
    mutate,
    createRoom,
  };
};

export const useDiscoverableRooms = (workspaceId: string) => {
  const url = workspaceId ? `/v1/workspaces/${workspaceId}/rooms/catalog` : null;

  const {
    data: room,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'room.index'>>(url, fetcher, {
    keepPreviousData: true,
  });

  const joinRoom = async <RouteName = 'room.join'>(
    id,
    { setErrors, setIsCreating, onSuccess, payload }: Groupthink.CreateOperationOptions<RouteName>
  ) => {
    apiRequest<RouteName>(
      workspaceId ? `/v1/workspaces/${workspaceId}/rooms/` + id + '/join' : '',
      mutate,
      'POST',
      {
        setErrors,
        setLoading: setIsCreating,
        payload,
        onSuccess,
      }
    );
  };

  return {
    rooms: room?.data,
    isLoading,
    isError: error,
    mutate,
    joinRoom,
  };
};
