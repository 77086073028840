import { Link as PrettyLink } from '@mui/joy';
import Link from 'next/link';
import React from 'react';

export const ForgotPasswordLink = (props) => {
  const { email } = props;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '.5rem',
        justifyContent: 'space-between',
      }}>
      <PrettyLink component={Link} sx={{ fontSize: '.825rem' }} href={`/forgot?email=${email}`}>
        Forgot your password
      </PrettyLink>
    </div>
  );
};
