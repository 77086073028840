import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Stack from '@mui/joy/Stack';
import React from 'react';

import { Modal } from '@/components/reusable';
import { AppStoreBadge } from '@/components/reusable';

const OpenInAppModal = ({ open, setHandleLoginLinkInBrowser }) => {
  const isClient = typeof window !== 'undefined';
  const isApplePlatform = isClient && /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isAndroidPlatform = isClient && /Android/i.test(navigator.userAgent);
  const [userOverride, setUserOverride] = React.useState(false);

  const platform = isApplePlatform ? 'ios' : isAndroidPlatform ? 'android' : null;
  const platformName = isApplePlatform ? 'iOS' : isAndroidPlatform ? 'Android' : 'mobile';

  const handleOpenLoginLinkInApp = () => {
    // get current window.location
    const currentUrl = window.location.href;

    // replace the currentUrl host with the app scheme
    const appUrl = currentUrl
      .replace(window.location.host, '')
      .replace(/^https?:\/\//, 'groupthink://');

    // open the url in the app
    window.location.href = appUrl;
  };

  return (
    <Modal
      open={open && !userOverride}
      onClose={() => {
        setHandleLoginLinkInBrowser(true);
        setUserOverride(true);
      }}
      title="Get the Groupthink app!"
      description={`Groupthink is now available on ${platformName}.`}>
      {(isApplePlatform || isAndroidPlatform) && (
        <Box pb={2}>
          <AppStoreBadge platform={platform} />
        </Box>
      )}
      <Stack direction="row" spacing={2}>
        <Button variant="solid" color="primary" size="sm" onClick={handleOpenLoginLinkInApp}>
          Open the app
        </Button>
      </Stack>
    </Modal>
  );
};

export default OpenInAppModal;
