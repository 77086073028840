import { LinkProps, TypographySystem } from '@mui/joy';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import * as React from 'react';

import { Modal } from '@/components/reusable';

type TypographyLevel = keyof TypographySystem | 'inherit';

interface ConfirmedActionLinkProps {
  buttonText: LinkProps['children'];
  color: LinkProps['color'];
  disabled: LinkProps['disabled'];
  onConfirm: () => void;
  confirmButtonText: string;
  title: string;
  message: string;
  isConfirming: boolean;
  level: TypographyLevel;
  fontWeight: string;
}
const ConfirmedActionLink = (props: ConfirmedActionLinkProps) => {
  const {
    buttonText,
    color = 'neutral',
    disabled = false,
    onConfirm,
    confirmButtonText,
    title,
    message,
    isConfirming,
    level = 'body-md',
    fontWeight = 'bold',
  } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Typography level={level} sx={{ fontWeight: fontWeight }}>
        <Link
          underline="always"
          component="button"
          color={color}
          onClick={() => setOpen(true)}
          disabled={disabled}>
          {buttonText}
        </Link>
      </Typography>
      <Modal
        variant="dialog-warning"
        title={title}
        description={message}
        open={open}
        onClose={() => setOpen(false)}>
        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
          <Button variant="plain" color="neutral" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="solid"
            color="danger"
            disabled={isConfirming}
            loading={isConfirming}
            onClick={onConfirm}>
            {confirmButtonText}
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmedActionLink;
